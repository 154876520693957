@import '../../assets/scss/_variables.scss';

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  padding: 24px 0;

  width: 100%;

  &.--white {
    color: white;
  }

  &.--black {
    color: black;
  }

  &.--small {
    font-size: 14px;
    width: unset;
    padding: 0;
  }
  &.--large {
    font-size: 28px;
  }

  &-main {
    display: flex;
    flex-direction: row;

    font-size: 28px;
    line-height: 36px;

    &-container {
      margin-left: 12px;

      &.--small {
        margin-left: 0;
      }

      &-name {
        font-size: 28px;
        line-height: 36px;

        &.--small {
          font-size: 14px;
          line-height: unset;
        }
        &.--large {
          font-size: 28px;
        }

        svg {
          width: 53px;
        }
      }
      &-poweredBy {
        font-size: 14px;
        line-height: 20px;
        text-align: right;

        &.--small {
          font-size: 10px;
          line-height: unset;
        }
        &.--large {
          font-size: 14px;
        }
      }
    }
  }
}

.back {
  position: sticky;
  font-size: 30px;
  left: 16px;
}
