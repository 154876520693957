@import '../../assets/scss/_variables.scss';

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 12px 40px;

  font-weight: 900;
  font-size: 14px;
  line-height: 26px;

  text-align: center;
  text-transform: uppercase;

  color: $secondary-color;

  span {
    margin-right: 16px;
    margin-bottom: 16px;
    font-size: 85px;
    color: $primary-color;
  }
}
