@import '../../assets/scss/_variables.scss';

.results {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      text-align: center;
      margin-bottom: 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
    }

    &-items {
      width: 100%;

      &-item {
        display: flex;
        align-items: center;
        flex-direction: row;

        padding: 12px 20px;
        margin-bottom: 8px;

        border: 1px solid #e0e0e0;
        border-radius: 4px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-type {
          font-size: 20px;
          line-height: 18px;
          flex: 1;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }

        &-value {
          font-size: 36px;
          line-height: 28px;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 34px;
        }

        &-icon {
          padding-right: 16px;
          stroke: #57575f;
        }
      }
    }
  }

  &-buttonGroup {
    position: sticky;

    width: 100%;
    bottom: 16px;
    left: 0;
    right: 0;

    margin-top: 16px;
  } // end &-buttonGroup
} // end .dashboard
