@import '../../assets/scss/_variables.scss';

.button {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #e5e5e5;
  border-radius: 3px;

  margin-top: 16px;
  padding: 9px 24px;

  text-transform: uppercase;
  color: white;

  outline: none;
  border: none;

  font-weight: 900;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}

.--primary {
  background: $primary-color;
}
.--secondary {
  background: #0a2b37;
}
.--outline-primary {
  color: $primary-color;
  border: 1px solid $primary-color;
  background: transparent;
}
.--outline-secondary {
  color: #0a2b37;
  border: 1px solid #0a2b37;
  background: transparent;
}
.--disabled {
  opacity: 0.2;
}
