@import './assets/scss/_variables.scss';

html,
body,
#root {
  font-family: apercu;
  background: #f6f8fa;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;

  &.is-locked {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  } // end &.is-locked

  .hideMobile {
    display: none;

    @include lg() {
      display: block;
    }
  }

  .hideDesktop {
    display: block;

    @include lg() {
      display: none;
    }
  }
} // end html, body, #root

body {
  margin: 0;
} // end body

* {
  box-sizing: border-box;
}

h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 35px;
}
