@import '../../assets/scss/_variables.scss';

.verify {
  &-container {
    width: 100%;
    text-align: center;

    & div input {
      font-family: unset !important;
      background: #edf8f7;

      padding-left: 0 !important;
      font-size: unset !important;

      border: 1px solid #07bd80 !important;
      box-shadow: 0px 2px 6px rgba(7, 189, 128, 0.2) !important;

      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }

      &:last-of-type {
        margin-right: 0;
      } // end &:last-of-type

      @include sm() {
        margin-right: 18px;
      }
    } // end &-input
  } //end &-container
} //end .verify
