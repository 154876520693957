@use 'sass:math';

.capture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #3c3c3c;

  $red: rgb(232, 4, 21);
  $white: white;
  $grey: rgb(162, 162, 162);
  $cont-size: 60px;
  $outer-size: $cont-size - 14;
  $inner-size: $outer-size - 10;
  $border-size: 3px;
  $sec: 1s;
  $bezier: cubic-bezier(0.4, -0.9, 0.9, 1);

  @mixin animation($name) {
    @-webkit-keyframes #{$name} {
      @content;
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-o-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    }
  }

  @mixin animation-use($name, $time, $easing) {
    -webkit-animation: $name $time infinite $easing;
    -moz-animation: $name $time infinite $easing;
    -o-animation: $name $time infinite $easing;
    animation: $name $time infinite $easing;
  }

  @mixin animate($val, $colon) {
    @include animation(to_ + $val) {
      @for $i from 1 to $val {
        #{math.div(100, $val*$i)}% {
          @if ($i < 10) {
            content: $colon + '0' + $i;
          } @else {
            content: $colon + '' + $i;
          }
        }
      }
    }
  }

  @mixin pseudo($content) {
    position: relative;
    content: $content;
  }

  @mixin center($val) {
    position: absolute;
    left: 50%;
    top: 50%;
    width: $val;
    height: $val;
    margin: math.div(-$val, 2) 0 0 math.div(-$val, 2);
  }

  @mixin delay($time) {
    -webkit-animation-delay: $time;
    animation-delay: $time;
  }

  @mixin once() {
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @include animate(24, '');
  @include animate(60, ':');
  @include animate(100, ':');

  @include animation(time) {
    to {
      top: 100%;
      opacity: 1;
    }
  }

  @include animation(stop) {
    70% {
      border-radius: 3px;
      @include center($inner-size - 14);
    }
    100% {
      border-radius: 3px;
      @include center($inner-size - 10);
    }
  }

  &-canvas {
    width: 100%;
    height: 100%;
  }

  &-record {
    position: absolute;
    bottom: 24px;

    .recorder-container {
      position: relative;
      width: $cont-size;
      height: $cont-size;

      #btn {
        display: none;

        & + label:before {
          @include center($outer-size);
          content: '';
          -webkit-transform: translate(-$border-size, -$border-size);
          -ms-transform: translate(-$border-size, -$border-size);
          transform: translate(-$border-size, -$border-size);
          border-radius: 50%;
          border: $border-size solid $white;
          cursor: pointer;
          box-sizing: unset;
        }

        & + label:after {
          @include center($inner-size);
          content: '';
          border-radius: math.div($inner-size, 2);
          background: $red;
          cursor: pointer;
        }

        & + label:before.disabled {
          border: $border-size solid $grey !important;
        }

        & + label:after.disabled {
          border: $border-size solid $grey !important;
        }

        &:checked {
          & + label:after {
            @include animation-use(stop, 0.5 * $sec, $bezier);
            @include once();
          }

          & ~ .time {
            @include animation-use(time, 0.3 * $sec, linear);
            @include once();
            animation-delay: 0.3 * $sec;
          }

          & ~ .time .h_m:after {
            @include animation-use(to_24, 86400 * $sec, linear);
            @include delay(1800 * $sec);
          }

          & ~ .time .h_m:before {
            @include animation-use(to_60, 3600 * $sec, linear);
            @include delay(30 * $sec);
          }

          & ~ .time .s_ms:before {
            @include animation-use(to_60, 60 * $sec, linear);
            @include delay(0.5 * $sec);
          }

          & ~ .time .s_ms:after {
            @include animation-use(to_100, $sec, linear);
          }
        }
      }
    }

    &.--on {
      background: #c9591b;
    }

    &.--off {
      background: transparent;
    }
  }

  &-time {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;

    position: absolute;
    top: 28px;

    font-size: 19px;
    line-height: 24px;

    text-align: center;

    &-dot {
      width: 6px;
      height: 6px;

      margin-right: 8px;

      background: #c9591b;
      border-radius: 80px;
    }
  }

  &-countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;

    position: absolute;

    font-size: 100px;
  }
}
