.forgotPassword {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  h1 {
    margin-bottom: 24px;
  }
  .form {
    &-item {
      background: #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 3px 4px rgba(37, 69, 151, 0.12);
      border-radius: 3px;

      padding: 20px;

      &.--text {
        border-right: none;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 21px;

        color: #000000;
      } // end &.--text

      &.--input {
        border-left: none;

        ::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 21px;

          color: #000000;

          opacity: 0.3;
        } // end ::placeholder
      } // end &.--input
    } // end &-item
  } // end &-form
} // end .signup
.forgotPasssword {
  margin: 26px 0;

  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;

  color: #000000;

  text-decoration: none;
}
