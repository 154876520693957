@import '../../assets/scss/_variables.scss';

.landing {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  height: 100%;

  &-logo {
    width: 340px;
  }

  &-gradient {
    position: fixed;
    width: 130%;
    height: 64%;
    top: 48%;

    z-index: 0;

    background: linear-gradient(180deg, #07bd80 0%, #fdf255 100%);
    opacity: 0.2;
    transform: rotate(-11.83deg);

    @include lg() {
      top: 61%;
    }
  }

  &-dots {
    position: fixed;
    width: 110px;
    height: 240.13px;

    z-index: 1;

    transform: rotate(90deg);
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    max-height: 650px;
    width: 100%;

    z-index: 0;

    @include lg() {
      align-items: flex-end;
      justify-content: center;
      flex-direction: row;
    }

    &-demoContainer {
      position: relative;
      margin: auto 0;
      margin-left: 56px;

      @include lg() {
        margin-left: 0;
      }

      &-vitals {
        height: 0;

        @include lg() {
          height: unset;
        }

        &-stats {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px 8px;

          width: 108px;
          height: 41px;

          background: #0a2b37;
          border-radius: 4.8169px 4.8169px 0px 4.8169px;

          transform: translateY(29px) translateX(-52px);

          color: white;
          font-size: larger;

          box-sizing: unset;

          @include lg() {
            width: 207.65px;
            height: 78.83px;
            transform: translateY(100px) translateX(-168px);
            font-size: 38px;
          }
        }

        &-line {
          z-index: 1;
          width: 40px;

          transform: translateY(35px) translateX(54px) rotate(-116deg);

          border: 1px solid #0a2b37;

          @include lg() {
            width: 100px;
            transform: translateY(112px) translateX(12px) rotate(-116deg);
          }
        }

        &-crystal {
          width: 41px;
          height: 17px;
          z-index: 2;
          transform: translateY(45px) translateX(80px) rotate(14.38deg);

          @include lg() {
            transform: translateY(145px) translateX(80px) rotate(14.38deg);
          }
        }
      }

      &-face {
        height: 306px;
        transform: translateX(24px);
        z-index: 0;

        @include lg() {
          height: 400px;
          transform: translateY(-10px) translateX(2px);
        }
      }
    }

    &-authContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @include md() {
        justify-content: space-around;
        height: 100%;
      }

      @include lg() {
        justify-content: center;
        width: 33%;
        margin-left: 60px;
      }

      h1 {
        font-size: 40px;

        @include lg() {
          margin-top: 111px;
        }
      }

      &-buttonGroup {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        width: 100%;

        :first-child {
          margin-right: 10px;
        }

        @include lg() {
          flex-direction: column;
          :first-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
