@import '../../assets/scss/_variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  margin-bottom: 24px;

  background: linear-gradient(
    180deg,
    rgba(7, 189, 128, 0.3) 0%,
    rgba(253, 242, 85, 0.3) 100%
  );

  width: 100%;

  &-logo {
    display: flex;
    flex-direction: row;

    font-size: 28px;
    line-height: 36px;

    &-container {
      margin-left: 12px;

      &-name {
        font-size: 28px;
        line-height: 36px;
      }
      &-poweredBy {
        font-size: 14px;
        line-height: 20px;
        text-align: right;
      }
    }
  }
}

.back {
  position: sticky;
  font-size: 30px;
  left: 16px;
}
