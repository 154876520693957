@import '../../assets/scss/_variables.scss';

.page {
  max-width: 100%;
  height: 100%;

  padding: 0 16px 16px;

  @include md() {
    width: $screen-md;
    margin: 0 auto;
  }

  & .buttonGroup {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 43.95%
    );
    padding: 16px 0;
  } // end &-buttonGroup

  & .statusMessage {
    border-radius: 4px;
    padding: 16px 8px;
    margin-bottom: 24px;

    width: 100%;

    &.--success {
      background: $primary-color;
      color: white;
    }
    &.--error {
      background: $error-color;
      color: white;
    }
  } // end &-statusMessage
}
