.signup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  h1 {
    margin-bottom: 24px;
  }

  .form {
    & .dropdown {
      flex: 1;

      & .dropdown-menu {
        width: 100%;
        padding: 0;

        :last-child {
          border-bottom: none;
        }

        &-item {
          width: 100%;
          padding-left: 16px;
          border-bottom: 1px solid lightgrey;
          font-size: 17px;
          padding: 16px;

          &.--selected {
            background: #0000000a;
          }
        }
      }
    }

    &-item {
      background: #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 3px 4px rgba(37, 69, 151, 0.12);
      border-radius: 3px;
      outline-style: none;

      padding: 20px;

      &.--text {
        border-right: none;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 21px;

        color: #000000;
      } // end &.--text

      &.--input {
        border-left: none;

        ::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 21px;

          color: #000000;

          opacity: 0.3;
        } // end ::placeholder
      } // end &.--input

      &.--dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        border: 1px solid #ced4da;
        border-left: none;

        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 21px;

        color: #000000;
      } // end &.--input
    } // end &-item
  } // end &-form
} // end .signup
