@import '../../assets/scss/_variables.scss';

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  &-instructions {
    margin-bottom: 16px;

    &-item {
      margin: 0 16px;

      img,
      svg {
        margin-right: 16px;
      }
    }
  } // end &-instructions

  &-videoContainer {
    filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.2));

    video {
      width: 100%;
      margin-bottom: 26px;
    } // end video
  }
} // end .dashboard
.uploading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 12px 40px;

    font-weight: 900;
    font-size: 14px;
    line-height: 26px;

    text-align: center;
    text-transform: uppercase;

    color: $secondary-color;

    span {
      margin-right: 16px;
      margin-bottom: 16px;
      font-size: 85px;
      color: $primary-color;
    }
  }
}
